<template>
  <v-card :loading="isLoading">
    <v-card-text v-if="companyId">
      <ProviderList
        v-if="companyId"
        :company-id="companyId"
        :show-search="true"
        :show-add="true"
        :show-edit="true"
      />
    </v-card-text>
    <v-card-text v-else>
      Şirket tedarikçilerini listelemek için üst menüden bir yönetim şirketi
      seçin.
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import ProviderList from "@/view/content/companies/ProviderList";

export default {
  components: {
    ProviderList,
  },
  computed: {
    ...mapGetters(["companyId"]),
  },
};
</script>
